button {
  &:disabled {
    cursor: pointer;
  }
  &.disabled {
    cursor: pointer;
  }
}
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}
