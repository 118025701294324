// Here you can add other styles
.c-sidebar.c-sidebar-light .c-sidebar-brand {
    color: #fff;
    background: rgba(0, 0, 21, 0.2);
}

// Stripe Card
label {
    /* color: #32325d; */
    font-weight: 300;
    letter-spacing: 0.025em;
  }
  
  button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #56568a;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
  }
  
  button:hover {
    opacity: 0.9;
    cursor: pointer;
    // background-color: #32325d;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }
  
  input::placeholder {
    color: #383847;
  }
  
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
  
  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
  
  .StripeElement.PaymentRequestButton {
    height: 40px;
  }

  .container.block {
    display: inline-block;
    width: 100% !important;
  }

  .date-disabled {
    background-color: #fff;
    color: darkgray;
    font-weight: initial;
    box-shadow: none;

    &:hover {
      transform: none;
      cursor:default;
      box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
      opacity: initial;
      box-shadow: none;
    }
  }

  .date-enabled {
    background-color: white;
    color: #455a64;
    box-shadow: none;
    font-weight: 400;

    &:hover {
      color: indianred;
    }
  }

  .MuiSvgIcon-root {
    vertical-align: middle;
  }

  /***********/
  // masonry
  .my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
  }
  /* Style your items */
  .my-masonry-grid_column > div {
    margin-bottom: 10px;
    cursor: pointer;
    padding: 1em;
    display: flow-root;
    
    & .hoverHeader {
      display: none;
    }
    & button {
      visibility: hidden;
    }
  }
  .my-masonry-grid_column > div > img {
    width: 100%;
    border-radius: 2px;
    position: relative;
    transition: all 0.3s ease;
    filter: brightness(80%);
  }
  .my-masonry-grid_column > div:hover {
    filter: brightness(100%);
    z-index: 50;
    box-shadow: 3px 3px 7px 0px #7a7a7a;

    & .hoverLabel {
      visibility: visible;
    }
    & .hoverHeader {
      position: absolute;
      top: 25px;
      z-index: 1;
      padding: 10px;
      display: block;
      background-color: #ffffff99;
      color: #616161;
      border-left: solid 3px;
    }
    & .galleryTitle {
      display: none;
    }
    & button {
      visibility: visible;
    }
  }
  .hoverLabel {
    padding: 5px;
    display: inherit;
  }
  .center-masonry {
    background: rgb(233, 232, 232);
  }
  .labelWrapper {
    min-height: 35px;
  }
  /***********/

  /**** pink to orange gradient ***/

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#febbbb+0,f6e6b4+100 */
.el_gradiento {
  background: #febbbb; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  #febbbb 0%, #f6e6b4 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  #febbbb 0%,#f6e6b4 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  #febbbb 0%,#f6e6b4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#febbbb', endColorstr='#f6e6b4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}


@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-editor {
  min-height: 10rem;
}

